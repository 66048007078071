import React, { useState } from "react";
import HeroMobileGif from "../../source/gifconcrete.gif";
import { ButtonContent } from "../global/boton/ButtonContent";
import QRcode from "../../source/cardenas-QR.png";

function HeroVideo() {
  const [QRCode, setQRCode] = useState(false);
  return (
    <>
      <div>
        {QRCode ? (
          <div className="fixed z-20 overflow-y-auto top-[45%] left-[45%] w-full">
            <div className="flex items-center justify-center min-height-100vh self-center justify-items-center px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-900 opacity-95" />
              </div>
              <div className="relative z-50">
                <button
                  className="text-white text-[20px] top-0 left-[10%] absolute bg-red-500 px-2 z-50"
                  onClick={() => {
                    setQRCode(false);
                  }}
                >
                  X
                </button>
                <img src={QRcode} alt="QR Code" />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="video-container">
        <div className="md:block hidden">
          <iframe
            src="https://www.youtube.com/embed/7WiKCZrjMb4?controls=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=7WiKCZrjMb4"
            className="bg-video"
            muted
            title="bg-Video"
          ></iframe>
        </div>
        <div className="md:hidden block">
          <img
            src={HeroMobileGif}
            className="w-full h-[700px] object-cover"
            alt="bg-Video"
          ></img>
        </div>
      </div>

      <div className="md:text-start text-center  md:pt-[60px] md:pb-[60px] top-[30%] left-0 pl-1 pr-1 absolute text-white md:top-[40%] md:left-[15%]">
        <h1>
          For A Stronger <br></br>Foundation
        </h1>
        <p>
          Our mission at Cardenas Concrete & Landscaping Works, LLC is to
          perform the highest quality <br></br>services in the area, at fair and
          competitive prices.
        </p>
        <ButtonContent btnStyle="three" bRadius="sm" />
        <button
          onClick={() => {
            setQRCode(!QRCode);
          }}
          className={`
                boton
                my-3
                mx-2
                three
                px-6
                py-4
                w-52
                rounded-sm
            `}
        >
          <span>QR Code</span>
        </button>
      </div>
    </>
  );
}

export default HeroVideo;
