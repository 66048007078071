import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { Link, NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import ScrollProgressBar from "./ScrollProgressBar";

function NavBar() {
  const { rpdata } = useContext(GlobalDataContext);

  //Recorrer el array de los servicios
  const subitems = rpdata?.dbServices?.map((item) => {
    return {
      link: `/services/${item.name.replaceAll(" ", "-").toLowerCase()}`,
      name: item.name,
    };
  });

  const Navigation = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "About",
      path: "/about",
    },
   
   
    {
      name: "Gallery",
      path: "/gallery",
    },
    {
      name: "Services",
      path: "/services",
      child: true,
      submenu: [...(subitems ? subitems : [])],
    },
    {
      name: "Videos",
      path: "/videos",
    },
    {
      name: "Reviews",
      path: "/reviews",
    },
    {
      name: "Contact",
      path: "/contact",
    },
  ];

  return (
    <div className="absolute z-50 w-full">
      <header>
        <ScrollProgressBar/>
        <nav className="flex flex-wrap items-center justify-between w-full py-2 md:py-6 md:px-20 px-4 text-lg text-white">
          <div className="md:w-[50%] w-[50%]">
            <img
              src={rpdata?.dbPrincipal?.logo}
              className="md:w-[30%] w-[100%]"
              alt="Not Found"
              loading="lazy"
            />
          </div>
          <div className="text-center flex justify-center">
            <button
              id="menu-button"
              className="cursor-pointer md:hidden block w-[100%] burguer-menu"
              onClick={() => {
                document.querySelector("#menu").classList.toggle("hidden");
              }}
            >
              <GiHamburgerMenu />
            </button>
          </div>
          <div
            className="hidden w-full md:flex md:items-center md:w-[50%] justify-end md:bg-transparent bg-white rounded-sm" id="menu"
          >
            <ul className="pt-4 text-base text-gray-700 md:flex md:justify-between md:pt-0 p-4">
              {Navigation.map((item, index) => {
                return (
                  <li key={index} className="md:flex md:relative group">
                    {item.child ? (
                      <Link
                        to="#"
                        className="md:p-4 py-2 px-4 block md:text-white text-black"
                        onClick={
                          () => {
                            document.querySelector("#mobilemenu").classList.toggle("hidden");
                          }
                        }
                      >
                        {item.name}
                        <i className="fas fa-angle-down ml-2"></i>
                      </Link>
                    ) : (
                      <Link to={item.path} className="md:p-4 py-2 px-4 block md:text-white text-black" >{item.name}</Link>
                    )}
                    {item.child && (
                      <ul className="md:absolute sm:overflow-y-scroll hidden z-10 bg-white p-3 w-60 top-14 transform scale-0 group-hover:scale-100 transition duration-150 ease-in-out origin-top" id="mobilemenu">
                        {item.submenu.map((subItem, subIndex) => {
                          return (
                            <li key={subIndex} className="hover:bg-slate-100 leading-8 ml-4">
                              <NavLink to={`${subItem.link.replace(" " , "-").toLowerCase()}`}>{subItem.name}</NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default NavBar;
