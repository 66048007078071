import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function BlockHomeOne() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <section className="w-full flex justify-center md:py-32 py-10">
        <div className="max-w-5xl md:flex md:flex-row flex flex-col-reverse md:p-0 px-2 content-reverse">
          <div className="text-center">
            <h2 className="separator-about">{rpdata?.dbSlogan?.[1].slogan}</h2>
            <p className="md:px-20">{rpdata?.dbHome?.[0].text}</p>
            <div className="flex w-full justify-center">
              <ul className="text-start md:px-4">
                {rpdata?.dbServices?.slice(0, 3).map((item, index) => {
                  return (
                    <li key={index} className="py-2">
                      <i className="fa fa-arrow-right mr-2"></i>
                      {item.name}
                    </li>
                  );
                })}
              </ul>
              <ul className="text-start md:px-4">
                {rpdata?.dbServices?.slice(3, 6).map((item, index) => {
                  return (
                    <li key={index} className="py-2">
                      <i className="fa fa-arrow-right mr-2"></i>
                      {item.name}
                    </li>
                  );
                })}
              </ul>
            </div>
            <ButtonContent btnStyle="three" bRadius="sm" />
          </div>
        </div>
      </section>
    </>
  );
}

export default BlockHomeOne;
