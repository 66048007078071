import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";


function BlockAboutTwo() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <section className="w-full flex justify-center py-32">
        <div className="max-w-6xl md:flex md:flex-row-reverse flex flex-col md:p-0 md:px-6 px-2 content-reverse">
          <div className="md:w-[50%]">
            <div
              className="w-full md:h-[600px] h-[350px] bg-cover bg-center"
              style={{ backgroundImage: `url("${rpdata?.gallery?.[9]}")` }}
            ></div>
          </div>
          <div className="md:w-[50%] py-4 md:px-8 px-3 self-center md:text-start text-center">
            <h2 className="separator-about">{rpdata?.dbSlogan?.[4].slogan}</h2>
            <p>{rpdata?.dbAbout?.[1].text}</p>
            <ButtonContent btnStyle='one' bRadius='full' />

            <div className="w-full">
              <span className="separator-about"></span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BlockAboutTwo;
