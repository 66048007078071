import React, { useState } from 'react'
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";


const Login = ({setOpeFormtype, setLogeado}) => {
  const [err, setErr] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target[0].value;
    const password = e.target[1].value;

    try {
      await signInWithEmailAndPassword(auth, email, password);
      setLogeado(true)
      localStorage.setItem('auth', JSON.stringify({ logeado: true }));
      setOpeFormtype('')
    } catch (err) {
      setErr(true);
    }
  };

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="w-full h-full  flex flex-col justify-center items-center gap-5">
        {/* <span className="logo">Inter Chat</span> */}
        <h3 className="text-[30px]">Login</h3>
        <form onSubmit={handleSubmit} className='flex flex-col gap-4 w-full'>
          <input type="email" placeholder="email"  className='w-full h-[60px] rounded-lg px-3 shadow-lg'/>
          <input type="password" placeholder="password" className='w-full h-[60px] rounded-lg px-3 shadow-lg' />
          <button className='bg-red-600 w-full p-3 rounded-lg text-white font-bold'>Sign in</button>
          {err && <span>Something went wrong</span>}
        </form>
        <p>You don't have an account? <button onClick={() => setOpeFormtype('regiter')} className='text-red-600'>Register</button></p>
      </div>
    </div>
  )
}

export default Login