import React from "react";
import Search from "./Search";


const Sidebar = ({ isSidebarHidden, setIsSidebarHidden }) => {



  return (
    <div className={` ${isSidebarHidden ? "hidden" : ""}`}>
      <Search setIsSidebarHidden={setIsSidebarHidden} />
    </div>
  );
};

export default Sidebar;
