import React from "react";
import CountUp from "react-countup";

function CounterHome({ bgimg,miles,location }) {
  return (
    <div>
      <div
        className="call-to-action-area bg-overlay mt-20"
        style={{ backgroundImage: `url("${bgimg}")` }}
      >
        <div className="w-full content-text md:flex md:text-start text-center justify-center item-center">
          <div className="text-star max-w-full w-full flex md:flex-row flex-col text-center justify-center item-center">
            <div className="flex flex-col justify-center text-center items-center px-6">
              <div className={`md:w-[20%] w-[30%] h-0.5 bg-white my-2`}></div>
              <div className="flex">
                <CountUp
                  end={100}
                  className="text-white text-[90px] font-semibold"
                />
                <span className="text-white text-[90px] font-semibold">%</span>
              </div>
              <span className="text-white text-[25px]">Satisfied Clients</span>
            </div>
            <div className="flex flex-col justify-center text-center items-center px-6">
              <div className={`md:w-[20%] w-[30%] h-0.5 bg-white my-2`}></div>
              <div className="flex">
                <CountUp
                  end={miles}
                  className="text-white text-[90px] font-semibold"
                />
              </div>
              <span className="text-white text-[25px]">Miles around {location}</span>
            </div>
            <div className="flex flex-col justify-center text-center items-center px-6">
              <div className={`md:w-[20%] w-[30%] h-0.5 bg-white my-2`}></div>
              <div className="flex">
                <CountUp
                  end={100}
                  className="text-white text-[90px] font-semibold"
                />
                <span className="text-white text-[90px] font-semibold">%</span>
              </div>
              <span className="text-white text-[25px]">Professionalism</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CounterHome;
