import React from "react";
import { ButtonContent } from "./boton/ButtonContent";

const CallToActionTwo = ({ SloganOne, SloganTwo, bgimg, phone }) => {
  return (
    <div>
      <div
        className="call-to-action-area bg-overlay"
        style={{ backgroundImage: `url("${bgimg}")` }}
      >
        <div className="w-full content-text md:flex md:text-start text-center justify-center">
          <div className="text-star max-w-4xl md:px-2 p-6 flex self-center justify-between">
            <div className="text-center md:max-w-4xl max-w-full w-full md:px-20 px-2">
              <h2 className="text-white">{SloganOne}</h2>
              <p className="text-white">{SloganTwo}</p>
              <ButtonContent btnStyle='three' bRadius='full' btnName={phone}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToActionTwo;
