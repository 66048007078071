import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
import ChatBotBtn from "../ChatBot/ChatBotBtn";
// import WhatsappChat from "../global/botonWhatsapp/BotonWhatsapp"

function Footer() {
  const { rpdata } = useContext(GlobalDataContext);

  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

  return (
    <>
      <div className="w-full flex justify-center bg-gray-400 bg-opacity-5 p-3 py-14 bg-footer text-white">
      <div class="elfsight-app-9a9abf62-8bcb-4251-b065-294b232bf1d6" data-elfsight-app-lazy></div>
     {/* boont elfsight */}
      {/* <div className="elfsight-app-3e187243-a441-4479-bfa2-d087db3cf40f"></div> */}
        <div className="md:max-w-screen-lg w-full">
          <div className="grid md:gap-4 gap-1 grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
            <div className="self-center pt-10">
              <img src={rpdata?.dbPrincipal?.logo} className="w-[85%]" alt="Not Found"/>
            </div>
            <div className="pt-10">
              <h5>About</h5>
              <p>{rpdata?.dbAbout?.[0].text.substring(0, 150) + "..."}</p>
              <ul className="social-area">
                {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.url} target="_blank" rel='noopener noreferrer'>
                        <i
                          className={`fab fa-${item.icon}`}
                          aria-hidden="true"
                        />
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="pt-10">
              <h5>Navigation</h5>
              <ul className="pl-2">
                <Link to="/">
                  <li className="py-2">
                    <i className="fa fa-arrow-right mr-1" onClick={goToTop()}></i>Home
                  </li>
                </Link>
                <Link to="/about">
                  <li className="py-2">
                    <i className="fa fa-arrow-right mr-1" onClick={goToTop()}></i>About
                  </li>
                </Link>
                <Link to="/services">
                  <li className="py-2">
                    <i className="fa fa-arrow-right mr-1" onClick={goToTop()}></i>Services
                  </li>
                </Link>
                <Link to="/gallery">
                  <li className="py-2">
                    <i className="fa fa-arrow-right mr-1" onClick={goToTop()}></i>Gallery
                  </li>
                </Link>
                <Link to="/contact">
                  <li className="py-2">
                    <i className="fa fa-arrow-right mr-1" onClick={goToTop()}></i>Contact
                  </li>
                </Link>
              </ul>
            </div>
            <div className="pt-10">
              <h5>Contact</h5>
              <ul className="pl-2">
                {rpdata?.dbPrincipal?.location.map((item, index) => {
                  return (
                    <Link to="/contact" key={index}>
                      <li className="py-2">
                        <i className="fa fa-map mr-1"></i>
                        {item.address}
                      </li>
                    </Link>
                  );
                })}
                {rpdata?.dbPrincipal?.phones?.map((item, index) => {
                  return (
                    <a href={`tel:+1${item.phone}`} key={index}>
                      <li className="py-2">
                        <i className="fa fa-phone mr-1"></i>
                        {item.phone}
                      </li>
                    </a>
                  );
                })}
                {rpdata?.dbPrincipal?.workdays.map((item, index) => {
                  return (
                    <li className="py-2" key={index}>
                      <i className="fa fa-calendar mr-1"></i>
                      {item.day}
                    </li>
                  );
                })}
                {rpdata?.dbPrincipal?.workHours.map((item, index) => {
                  return (
                    <li className="py-2" key={index}>
                      <i className="fa fa-clock mr-1"></i>
                      {item.hour}
                    </li>
                  );
                })}
                <li className="py-2">
                  <i className="fa fa-credit-card mr-1"></i>
                  {rpdata?.dbPrincipal?.paymentMethod}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      {/* <WhatsappChat/> */}
      {/* <WhatsappChat /> */}
      <div className="w-full align-self-center text-center text-white bg-footer py-4">
        <p>
          ©2022, Copy Right By {rpdata?.dbPrincipal?.name}. All Rights Reserved
        </p>
      </div>
      <div>
        <ChatBotBtn />
      </div>
    </>
  );
}

export default Footer;
